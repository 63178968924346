import { Semaphore } from 'async-mutex';
export class ReadWriteLock {
    constructor() {
        this.semaphore = new Semaphore(1); // Semaphore with 1 permit
        this.readers = 0; // Track the number of readers
        this.readersMutex = new Semaphore(1); // Protect access to `readers` count
    }
    async withReadLock(callback) {
        await this._acquireReadLock();
        try {
            return await callback();
        }
        finally {
            await this._releaseReadLock();
        }
    }
    async withWriteLock(callback) {
        await this._acquireWriteLock();
        try {
            return await callback();
        }
        finally {
            await this._releaseWriteLock();
        }
    }
    async _acquireReadLock() {
        // Increment the readers count
        await this.readersMutex.acquire();
        try {
            this.readers += 1;
            // If this is the first reader, block writers
            if (this.readers === 1) {
                await this.semaphore.acquire();
            }
        }
        finally {
            this.readersMutex.release();
        }
    }
    async _releaseReadLock() {
        // Decrement the readers count
        await this.readersMutex.acquire();
        try {
            this.readers -= 1;
            // If this was the last reader, release the writer block
            if (this.readers === 0) {
                this.semaphore.release();
            }
        }
        finally {
            this.readersMutex.release();
        }
    }
    async _acquireWriteLock() {
        // Writers acquire the main semaphore exclusively
        await this.semaphore.acquire();
    }
    async _releaseWriteLock() {
        // Writers release the main semaphore
        this.semaphore.release();
    }
}
